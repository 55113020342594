export const _host = () => {
    return process.env.NODE_ENV === 'development'
        ? 'https://localhost.uwb.edu.pl'
        : ''
}

export const call = async (method, _options = {}, content_type = 'application/json') => {
    const options = {
        method: 'GET',
        mode: 'cors',
        credentials: 'include',
        headers: {
            'Content-Type': content_type,
        },
        redirect: 'manual',
        referrerPolicy: 'origin',
        ..._options
    }

    if (!content_type) {
        delete options.headers['Content-Type']
    }

    const req = await fetch(`${_host()}/${method}`, options)

    const json = await req.json()
    if (req.status != 200 && req.status != 202) {
        if (req.status == 401 && json.error && json.errorCode == 401) {
            window.location.reload()
        }

        throw new Error(`Błąd komunikacji z serwerem: ${json.message}`)
    }

    return json
}

export const wait = async (ms) => { return new Promise(resolve => setTimeout(resolve, ms)) }