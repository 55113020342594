import { defineStore } from 'pinia'
import { call, wait } from '@/API.js'
import { useGlobalStore } from './global-store'

export const useUserStore = defineStore('user', {
  state: () => {
    return {
      user: null,
      inited: false
    }
  },

  actions: {
    setUser(u) {
      this.user = u
      this.inited = true
    },

    async waitForInit() {
      if (this.inited) return true
      await wait(100)
      return this.waitForInit()
    },

    async getUserSession() {
      try {
        const globalStore = useGlobalStore()
        const response = await call('api/session')
        this.setUser(response.user)
        globalStore.setAppInfo(response.app)
        return response.user
      } catch (err) {
        throw Error(`Nie można pobrać danych o sesji, proszę spróbować później`)
      }
    }
  },

  getters: {
    getUser: (state) => {
      return state.user
    },

    getUserFullName: (state) => {
      if (state.user) {
        return `${state.user.firstName} ${state.user.lastName}`
      }

      return `Gość`
    },

    isLoggedIn: (state) => {
      return state.user != null
    },

    isAdmin: (state) => {
      return state.user?.role == 'ADMIN' || state.user?.role == 'SUPERADMIN'
    },

    isSuperAdmin: (state) => {
      return state.user?.role == 'SUPERADMIN'
    }
  }
})
