import { createApp } from 'vue'
import { createPinia } from 'pinia'
import App from './App.vue'
import router from './router'
import * as Sentry from "@sentry/vue";
import { createVuestic } from 'vuestic-ui';



const pinia = createPinia()
const app = createApp(App)

Sentry.init({
    app,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
    ],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for tracing.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
    tracePropagationTargets: ["localhost", /^https:\/\/spa\.uwb\.edu\.pl/],

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

app.use(pinia)
app.use(createVuestic({
    config: {
        colors: {
            presets: {
                light: {
                    navBarUwB: '#862633'
                },
                dark: {
                    navBarUwB: '#FFF'
                }
            }
        }
    }
}))
app.use(router)
app.mount('#app')
