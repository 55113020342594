import { defineStore } from 'pinia'
import { call } from '@/API'

export const useGlobalStore = defineStore('global', {
  state: () => {
    return {
      isSidebarMinimized: false,
      appInfo: { version: 'unknown', hash: 'unknown' },
      appConfig: {},
      routerLoadingState: false
    }
  },

  actions: {
    toggleSidebar() {
      this.isSidebarMinimized = !this.isSidebarMinimized
    },

    setAppInfo(app) {
      this.appInfo = app
    },

    async getAppConfig() {
      try {
        const response = await call(`api/config`)
        this.appConfig = response
        return this.appConfig
      } catch (err) {
        throw Error(`Nie udało się pobrać konifguracji aplikacji, proszę spróbować ponownie`)
      }
    }
  },

  getters: {
    getAppInfo: (state) => {
      return state.appInfo
    }
  }
})
